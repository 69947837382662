<template>
    <div id="portfoliomenu">
        <a class="routerLink" href="#Apps">
            <span v-if="theme" class="menufont" @mouseenter="hover1 = true" @mouseleave="hover1 = false">: Apps
                <div v-if="$route.path == '/' && !hover1" class="cube" style="background-color: #ffe05c;" />
                <div v-else-if="$route.path == '/' && hover1" class="cube move" style="background-color: #ffffff;" />
                <div v-else-if="$route.path != '/' && hover1" class="cube moverev" style="background-color: #ffe05c;" />
            </span>
            <span v-else class="menufont night">Intro.
                <div v-if="$route.path == '/'" class="cube" style="background-color: #ffffff;" />
            </span>
        </a>
        <a class="routerLink" href="#Photography">
            <span v-if="theme" class="menufont" @mouseenter="hover2 = true" @mouseleave="hover2 = false">: Photography
                <div v-if="$route.path == '/Portfolio' && !hover2" class="cube" style="background-color: #ffe05c;" />
                <div v-else-if="$route.path == '/Portfolio' && hover2" class="cube move" style="background-color: #ffffff;" />
                <div v-else-if="$route.path != '/Portfolio' && hover2" class="cube moverev" style="background-color: #ffe05c;" />
            </span>
            <span v-else class="menufont night">Portfolio.
                <div v-if="$route.path == '/Portfolio'" class="cube" style="background-color: #ffffff;" />
            </span>
        </a>
<!--        <router-link tag="div" class="routerLink" to="/Blog">-->
<!--            <span v-if="theme" class="menufont" @mouseenter="hover3 = true" @mouseleave="hover3 = false">Blog.-->
<!--                <div v-if="$route.path == '/Blog' && !hover3" class="cube" style="background-color: #ffe05c;" />-->
<!--                <div v-else-if="$route.path == '/Blog' && hover3" class="cube move" style="background-color: #ffffff;" />-->
<!--                <div v-else-if="$route.path != '/Blog' && hover3" class="cube moverev" style="background-color: #ffe05c;" />-->
<!--            </span>-->
<!--            <span v-else class="menufont night">Blog.-->
<!--                <div v-if="$route.path == '/Blog'" class="cube" style="background-color: #ffffff;" />-->
<!--            </span>-->
<!--        </router-link>-->
        <a class="routerLink" href="#MediaArts">
            <span v-if="theme" class="menufont" @mouseenter="hover3 = true" @mouseleave="hover3 = false">: Media Arts
                <div v-if="$route.path == '/Blog' && !hover3" class="cube" style="background-color: #ffe05c;" />
                <div v-else-if="$route.path == '/Blog' && hover3" class="cube move" style="background-color: #ffffff;" />
                <div v-else-if="$route.path != '/Blog' && hover3" class="cube moverev" style="background-color: #ffe05c;" />
            </span>
            <span v-else class="menufont night">Blog.
                <div v-if="$route.path == '/Blog'" class="cube" style="background-color: #ffffff;" />
            </span>
        </a>
        <a class="routerLink" href="#Others">
            <span v-if="theme" class="menufont" @mouseenter="hover4 = true" @mouseleave="hover4 = false">: Others
                <div v-if="$route.path == '/About' && !hover4" class="cube" style="background-color: #ffe05c;" />
                <div v-else-if="$route.path == '/About' && hover4" class="cube move" style="background-color: #ffffff;" />
                <div v-else-if="$route.path != '/About' && hover4" class="cube moverev" style="background-color: #ffe05c;" />
            </span>
            <span v-else class="menufont night">About.
                <div v-if="$route.path == '/About'" class="cube" style="background-color: #ffffff;" />
            </span>
        </a>
    </div>
</template>

<script>
    export default {
        name: "PortfolioMenu",
        props: {
            theme: Boolean
        },
        data() {
            return {
                // thistheme: true,
                hover1: false,
                hover2: false,
                hover3: false,
                hover4: false,
            }
        },
        // watch: {
        //     theme: function () {
        //         this.thistheme = this.theme;
        //     }
        // },
        mounted() {
            console.log(this.$route.path);
        }
    }
</script>

<style scoped>
    #portfoliomenu {
        width: 18em;
        position: fixed;
        top: calc(30px + 180px + 15px);
        left: 20px;
        text-align: left;
    }
    .routerLink {
        display: block;
    }
    .menufont {
        position: relative;
        font-family: "Noto Serif SC";
        font-weight: 500;
        font-size: 2rem;
        text-align: left;
        padding: 0 10px 0 20px;
    }
    .menufont:hover {
        position: relative;
        font-family: "Noto Serif SC";
        font-weight: 500;
        font-size: 2rem;
        text-align: right;
        padding: 0 10px 0 20px;

        color: transparent;
        background-color: #0d1b2a;
        width: 100%;

        animation: titleShow 1s ease forwards;
    }
    .night {
        color: #ffe05c;
    }
    .night:hover {
        color: transparent;
        background-color: #ffe05c;
        width: 100%;

        animation: titleShowNight 1s ease forwards;
    }
    .cube {
        position: absolute;
        left: 0%;
        display: inline-block;
        min-width: 30px;
        min-height: 30px;
        animation: show 1s ease forwards;
    }
    /*.move {*/
    /*    animation: cubeMove .5s ease forwards;*/
    /*}*/
    .moverev {
        animation: cubeMove .5s ease forwards reverse;
    }

    @keyframes show {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
    @keyframes titleShow {
        0% { color: #ffffff00; background-color: #0d1b2a00; }
        100% { color: #ffffffff; background-color: #0d1b2aff; }
    }

    @keyframes titleShowNight {
        0% { color: #0d1b2a00; }
        100% { color: #0d1b2aff; }
    }

    @keyframes cubeMove {
        0% { left: 0%; opacity: 1; }
        100% { left: -20%; opacity: 0; }
    }
</style>