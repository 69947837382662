var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"icon",attrs:{"href":_vm.iconClass == 'bilibili-line'? 'https://space.bilibili.com/5026889' :
_vm.iconClass == 'weibo-fill'? 'https://weibo.com/reneedress' :
_vm.iconClass == 'github-fill'? 'https://github.com/ReneeDress' :
_vm.iconClass == 'linkedin-box-fill'? 'https://www.linkedin.com/in/yijun-l-555840126' :
_vm.iconClass == 'zhihu-line'? 'https://www.zhihu.com/people/lian-yi-qun-20' :
_vm.iconClass == 'wechat-fill'? '' :
_vm.iconClass == 'dribbble-line'? 'https://dribbble.com/ReneeDress' :
_vm.iconClass == 'behance-line'? 'https://www.behance.net/reneedress7c84' :
_vm.iconClass == 'douban-line'? 'https://www.douban.com/people/77103399/' :
'',"target":"_blank"}},[_c('svg',{staticClass:"icon-svg",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":_vm.iconName}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }