<template>
<!--    <div id="beian" :style="{ backgroundColor: theme ? '#0d1b2a' : '#ffe05c' }">-->
    <div id="beian">
        <a v-if="theme" class="footnote" href="https://beian.miit.gov.cn/#/Integrated/index">沪ICP备20015145号-1</a>
        <a v-else class="footnote" style="color: #ffe05c" href="https://beian.miit.gov.cn/#/Integrated/index">沪ICP备20015145号-1</a>
        <img style="display: inline-block; vertical-align: middle;" :src="beian" :width="17" :height="17" />
        <a v-if="theme" class="footnote" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402008390">沪公网安备 31010402008390号</a>
        <a v-else class="footnote" style="color: #ffe05c" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402008390">沪公网安备 31010402008390号</a>
    </div>
</template>

<script>
    import beian from '../assets/beian.png';
    export default {
        name: "Beian",
        props: {
            theme: Boolean
        },
        data() {
            return {
                // thistheme: true,
                beian: beian,
            }
        },
        // watch: {
        //     theme: function () {
        //         this.thistheme = this.theme;
        //     }
        // },
    }
</script>

<style scoped>
    #beian {
        display: block;
        padding: 2vh;
        overflow: hidden;
        position: relative;
        height: 4vh;
    }
    #beian:after {
        font-size: 0;
        /*内容为空*/
        content: "";
        /*高度为父级元素的100%*/
        height: 100%;
    }
    .footnote {
        vertical-align: middle;
    }
</style>