// 引入vue
import Vue from 'vue';
// 引入vue-router
import VueRouter from 'vue-router';
// 第三方库需要use一下才能用
Vue.use(VueRouter)

import Main from "./components/Main.vue";
import Portfolio from "./components/Portfolio.vue";
import About from "./components/About.vue";

// 定义routes路由的集合，数组类型
const routes = [
    // 单个路由均为对象类型，path代表的是路径，component代表组件
    { path:'/', component: Main },
    { path:'/Portfolio', component: Portfolio },
    { path:'/About', component: About },
    { path:"*", component: Main }
]

// 实例化VueRouter并将routes添加进去
const router = new VueRouter({
// ES6简写，等于routes：routes
    mode: 'history',
    routes,
    base: '/',
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

// 抛出这个这个实例对象方便外部读取以及访问
export default router