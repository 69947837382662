<template>
    <a class="icon" :href="
    iconClass == 'bilibili-line'? 'https://space.bilibili.com/5026889' :
    iconClass == 'weibo-fill'? 'https://weibo.com/reneedress' :
    iconClass == 'github-fill'? 'https://github.com/ReneeDress' :
    iconClass == 'linkedin-box-fill'? 'https://www.linkedin.com/in/yijun-l-555840126' :
    iconClass == 'zhihu-line'? 'https://www.zhihu.com/people/lian-yi-qun-20' :
    iconClass == 'wechat-fill'? '' :
    iconClass == 'dribbble-line'? 'https://dribbble.com/ReneeDress' :
    iconClass == 'behance-line'? 'https://www.behance.net/reneedress7c84' :
    iconClass == 'douban-line'? 'https://www.douban.com/people/77103399/' :
    ''" target="_blank">
        <svg class="icon-svg" aria-hidden="true" >
            <use :xlink:href="iconName"></use>
        </svg>
    </a>
</template>

<script>
    export default {
        name: "Icon",
        props: {
            iconClass: {
                type: String,
                required: true
            }
        },
        computed: {
            iconName () {
                return `#icon-${this.iconClass}`
            }
        }
    }
</script>

<style scoped>
    .icon-svg {
        width: 1em;
        height: 1em;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
    }
</style>