<template>
  <div id="app">
    <keep-alive>
      <router-view class="router" :theme="theme" v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view class="router" :theme="theme" v-if="!$route.meta.keepAlive"></router-view>
    <Beian :theme="theme" />
    <Menu :theme="theme" />
    <Logo :theme.sync="theme" :size="$route.path == '/'? '0' : '180' " />
    <div v-if="theme" class="light" @click="changeTheme" />
    <div v-else class="light nightlight" @click="changeTheme" />
    <a class="icon" href="mailto:linyijun@yijunstudio.xyz" style="width: 3rem; height: 3rem; left: calc(30px); bottom: calc(10rem + 1rem);">
      <img v-if="theme" :src="mail_0d1b2a" style="width: 100%; height: 100%;" />
      <img v-else :src="mail_ffe05c" style="width: 100%; height: 100%;" />
    </a>
    <Icon icon-class="weibo-fill" :style="{ color: theme ? '#0d1b2a' : '#ffe05c', fontSize: '3rem', left: 'calc(30px + .05em)', bottom: 'calc(2.2em + 1rem)'  }" />
    <Icon icon-class="douban-line" :style="{ color: theme ? '#0d1b2a' : '#ffe05c', fontSize: '3rem', left: 'calc(30px)', bottom: 'calc(1rem)'  }" />
    <Icon icon-class="wechat-fill" :style="{ color: theme ? '#0d1b2a' : '#ffe05c', fontSize: '3rem', left: 'calc(1.2em + 30px)', bottom: 'calc(1rem)'  }" />
    <Icon icon-class="zhihu-line" :style="{ color: theme ? '#0d1b2a' : '#ffe05c', fontSize: '3rem', left: 'calc(30px)', bottom: 'calc(1.1em + 1rem)' }" />
    <Icon icon-class="bilibili-line" :style="{ color: theme ? '#0d1b2a' : '#ffe05c', fontSize: '3rem', right: 'calc(1.2em + 30px)'  }" />
    <Icon icon-class="github-fill" :style="{ color: theme ? '#0d1b2a' : '#ffe05c', fontSize: '3rem', right: 'calc(30px)' }" />
    <Icon icon-class="linkedin-box-fill" :style="{ color: theme ? '#0d1b2a' : '#ffe05c', fontSize: '3rem', right: 'calc(4.8em + 30px)' }" />
    <Icon icon-class="dribbble-line" :style="{ color: theme ? '#0d1b2a' : '#ffe05c', fontSize: '3rem', right: 'calc(2.4em + 30px)'  }" />
    <Icon icon-class="behance-line" :style="{ color: theme ? '#0d1b2a' : '#ffe05c', fontSize: '3rem', right: 'calc(3.6em + 30px)'  }" />
  </div>
</template>

<script>
  import Menu from './components/Menu.vue';
  import Logo from './components/Logo.vue';
  import Beian from './components/Beian.vue';
  import Icon from './components/Icon.vue';
  import mail_0d1b2a from './assets/mail_0d1b2a.png';
  import mail_ffe05c from './assets/mail_ffe05c.png';
  export default {
    name: 'App',
    components: {
      Menu,
      Logo,
      Beian,
      Icon,
    },
    data() {
      return {
        theme: true,
        disabletheme: false,

        mail_0d1b2a: mail_0d1b2a,
        mail_ffe05c: mail_ffe05c,
      }
    },
    methods: {
      changeTheme() {
        if (!this.disabletheme) {
          this.theme = !this.theme;
          this.disabletheme = true;
          if (this.theme) {
            document.firstChild.nextSibling.style.animation = 'bkgColor .5s ease forwards reverse';
            setTimeout(() => {
              document.firstChild.nextSibling.style.animation = '';
              document.firstChild.nextSibling.style.backgroundColor = '#ffffff';
            }, 500)
          } else {
            document.firstChild.nextSibling.style.animation = 'bkgColor .5s ease forwards';
            setTimeout(() => {
              document.firstChild.nextSibling.style.animation = '';
              document.firstChild.nextSibling.style.backgroundColor = '#0d1b2a';
            }, 500)
          }
        }
        setTimeout(() => {
          this.disabletheme = false;
        }, 505);
        // console.log('changeTheme', this.theme);
      },
    },
    mounted() {
      console.log(this.$route.path);
    }
  }
</script>

<style>
  body {
    margin: 0;
  }
  #app {
    font-family: "Noto Serif SC", Avenir, Helvetica, Arial;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #0d1b2a;
    font-size: 1rem;
    padding: 0;
    margin: 0;
    user-select: none;
    -webkit-user-select: none;
    -moz-osx-user-select: none;
  }
  .light {
    min-width: 2vw;
    min-height: 2vw;
    background-color: #0d1b2a;

    position: fixed;
    top: 30px;
    left: 20px;
  }
  .light:hover {
    /*background-color: #ffe05c;*/
    animation: rotation 1s ease infinite;
    cursor: pointer;
  }
  .nightlight {
    background-color: #ffe05c;
  }
  .nightlight:hover {
    /*background-color: #0d1b2a;*/
    animation: rotation 1s ease infinite;
    cursor: pointer;
  }
  .router {
    min-height: 100vh;
  }
  .icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;

    /*color: #0d1b2a;*/
    position: fixed;
    bottom: 1rem;
  }
  .footnote {
    display: inline-block;
    font-size: .75rem;
    text-decoration: none;
    color: #707070;
    margin: 0 .75rem;
  }
  @keyframes rotation {
    0% { transform: rotate(0deg); }
    40% { transform: rotate(180deg); }
    100% { transform: rotate(180deg); }
  }
  @keyframes bkgColor {
    from { background-color: #ffffff; }
    to { background-color: #0d1b2a; }
  }
  .routerLink {
    text-decoration: none;
    color: #0d1b2a;
  }
  .routerLink:hover {
    cursor: pointer;
  }
  /*@keyframes logoSize {*/
  /*  from { width: 300px; height: 300px; }*/
  /*  to { width: 180px; height: 180px; }*/
  /*}*/
  /*.zoomIn {*/
  /*  animation: logoSize 1s ease forwards reverse;*/
  /*}*/
  /*.zoomOut {*/
  /*  animation: logoSize 1s ease forwards;*/
  /*}*/

  .content {
    width: 60vw;
    min-height: 10vh;
    margin: 0 22vw 0 18vw;
    text-align: left;
  }
</style>
