<template>
    <div id="portfolio">
        <div class="content">
            <a name="Apps" />
            <div class="subtitle">
                Apps
            </div>
            <div class="subcontent Apps">
                <router-link tag="div" v-for="item in apps" :key="item.title" class="app" :to="'/Portfolio#Apps/' + item.title">
                    <img :src="item.src" />
                </router-link>
            </div>
        </div>
        <div class="content">
            <a name="Photography" />
            <div class="subtitle">
                Photography
            </div>
            <div class="subcontent Photography">
                <div v-for="item in photos" :key="item.src" class="photo" @mouseenter="item.hover = true" @mouseleave="item.hover = false">
                    <div v-if="item.hover" style="width: 100%; height: 100%; background-color: #ffe05c20;">{{item.title}}</div>
                    <img :src="item.src" />
                </div>
            </div>
        </div>
        <div class="content">
            <a name="MediaArts" />
            <div class="subtitle">
                Media Arts
            </div>
            <div class="subcontent MediaArts">
                <a v-for="item in medias" :key="item.src" class="media" :href="item.link" target="_blank">
                    <img :src="item.src" />
                </a>
            </div>
        </div>
        <div class="content">
            <a name="Others" />
            <div class="subtitle">
                Others
            </div>
            <div class="subcontent">

            </div>
        </div>
        <PortfolioMenu :theme="theme" />
    </div>
</template>

<script>
    import PortfolioMenu from "../components/PortfolioMenu";

    import WSTMEnrollmentManagement from '../assets/apps/白月光.png';

    import DesktopBgd001 from "../assets/photography/DesktopBgd001.jpg";
    import DesktopBgd004 from "../assets/photography/DesktopBgd004.jpg";
    import DesktopBgd005 from "../assets/photography/DesktopBgd005.jpg";
    import DesktopBgd006 from "../assets/photography/DesktopBgd006.jpg";
    import DesktopBgd009 from "../assets/photography/DesktopBgd009.jpg";
    import Snapseed from "../assets/photography/Snapseed.jpg";
    import Snapseed4 from "../assets/photography/Snapseed4.jpg";
    import Snapseed5 from "../assets/photography/Snapseed5.jpg";
    import Snapseed8 from "../assets/photography/Snapseed8.jpg";
    import Snapseed9 from "../assets/photography/Snapseed9.jpg";
    import IMG_5749 from "../assets/photography/IMG_5749.jpg";
    import IMG_5750 from "../assets/photography/IMG_5750.jpg";
    import IMG_5751 from "../assets/photography/IMG_5751.jpg";

    import qnjs_20201231 from "../assets/medias/青鸟剧社的新年愿望只有一个.png";
    import hjkld_20201231 from "../assets/medias/小何的二零二零.png";
    import shusu_20190903 from "../assets/medias/shusu_20190903.jpg";
    import dianback from "../assets/medias/yeah.png";
    import twentyyears from "../assets/medias/二十岁的歌.jpg";
    export default {
        name: "Portfolio",
        components: {
            PortfolioMenu,
        },
        props: {
            theme: Boolean,
        },
        data() {
            return {
                apps: [
                    { title: 'WSTMEnrollmentManagement', src: WSTMEnrollmentManagement, link: '', describe: '', hover: false },
                    { title: 'ECUPL', src: '', link: '', describe: '', hover: false },
                    { title: 'SHULiving', src: '', link: '', describe: '', hover: false },
                    { title: '', src: '', link: '', describe: '', hover: false },
                ],
                photos: [
                    { title: '《我的遗愿清单》', src: DesktopBgd001, link: '', describe: '', hover: false },
                    { title: '', src: DesktopBgd004, link: '', describe: '', hover: false },
                    { title: '', src: DesktopBgd005, link: '', describe: '', hover: false },
                    { title: '', src: DesktopBgd006, link: '', describe: '', hover: false },
                    { title: '', src: DesktopBgd009, link: '', describe: '', hover: false },
                    { title: '', src: Snapseed, link: '', describe: '', hover: false },
                    { title: '', src: Snapseed4, link: '', describe: '', hover: false },
                    { title: '', src: Snapseed5, link: '', describe: '', hover: false },
                    { title: '', src: Snapseed8, link: '', describe: '', hover: false },
                    { title: '', src: Snapseed9, link: '', describe: '', hover: false },
                    { title: '', src: IMG_5749, link: '', describe: '', hover: false },
                    { title: '', src: IMG_5750, link: '', describe: '', hover: false },
                    { title: '', src: IMG_5751, link: '', describe: '', hover: false },
                ],
                medias: [
                    { title: '青鸟剧社的新年愿望只有一个...', src: qnjs_20201231,
                        link: 'https://mp.weixin.qq.com/s/ACjmqUlt01gV2xHc9KRKLA', describe: '完不成就等下一个新年。', hover: false },
                    { title: '何炅 | 小何的二零二零', src: hjkld_20201231,
                        link: 'https://mp.weixin.qq.com/s/MJauFapElTP5JkN-42tSFw', describe: '新年快乐。\n2021万事顺遂。', hover: false },
                    { title: '办一场拍卖会需要多少人？', src: hjkld_20201231,
                        link: 'https://mp.weixin.qq.com/s/GsJWe-xIQWhFjSNFA64spQ', describe: '《你出多少镑》幕后。', hover: false },
                    { title: '直击克里斯宾拍品首展盛况', src: hjkld_20201231,
                        link: 'https://mp.weixin.qq.com/s/75tEGjOcR0tP8h8wUV50ag', describe: '跟随青鸟台记者抢先看预出价。', hover: false },
                    { title: '迎新大戏 | 风暴如期 孤岛寻迹', src: hjkld_20201231,
                        link: 'https://mp.weixin.qq.com/s/yQkvCc42YMW76j1ELlZ7eA', describe: '', hover: false },
                    { title: '迎新大戏 | 童谣将已 生命无几', src: hjkld_20201231,
                        link: 'https://mp.weixin.qq.com/s/p01lhDGk19UmWjTYFCwcjQ', describe: '', hover: false },
                    { title: '迎新大戏 | 枪响人落 天高海阔', src: hjkld_20201231,
                        link: 'https://mp.weixin.qq.com/s/1dnoTG8nJhAmOYWZO42uEw', describe: '', hover: false },
                    { title: '独家 | 我们收到了7件新上大人的求职疑惑投稿', src: shusu_20190903,
                        link: 'https://mp.weixin.qq.com/s/XA15z-DnYL5Ub9Llsq9GXA', describe: '', hover: false },
                    { title: '二十岁的歌', src: twentyyears,
                        link: 'https://mp.weixin.qq.com/s/WJbTitZ8OSdXIObWPDyT0A', describe: '', hover: false },
                    { title: '今年的情人節也一起過吧。', src: hjkld_20201231,
                        link: 'https://mp.weixin.qq.com/s/9E7WfOhXq26Ej8dv5hM0Ng', describe: '', hover: false },
                    { title: '滇。', src: dianback,
                        link: '', describe: '', hover: false },
                ],
            }
        }
    }
</script>

<style scoped>
    #portfolio {
        padding: 4vh 0;
    }
    .content {
        padding: 0 2rem;
    }
    .subtitle {
        font-size: 2rem;
        font-weight: 200;
        text-align: left;
        /*padding: 0 2rem;*/
        margin: 1rem 0;
    }
    .subcontent {
        display: grid;
        /*grid-template-columns: repeat(auto-fill, minmax(200px,200px));*/
        /*grid-template-rows: repeat(auto-fill, minmax(200px,200px));*/
        /*grid-template-columns: repeat(auto-fill,minmax(180px,180px));*/
        /*grid-template-rows: repeat(auto-fill,minmax(180px,180px));*/
        gap: 0;
    }
    .Apps {
        grid-template-columns: repeat(auto-fill, minmax(400px,400px));
        grid-template-rows: repeat(auto-fill, minmax(200px,200px));
    }
    .Photography {
        grid-template-columns: repeat(auto-fill, minmax(200px,200px));
        grid-template-rows: repeat(auto-fill, minmax(200px,200px));
    }
    .MediaArts {
        grid-template-columns: repeat(auto-fill, minmax(400px,400px));
        grid-template-rows: repeat(auto-fill, minmax(170px,170px));
    }
    .app {
        position: relative;
        /*width: 320px;*/
        height: 180px;
        /*border-radius: 20px;*/
        background-color: #778da9;
        margin: 0 20px 20px 0;
        overflow: hidden;
    }
    /*.app:hover {*/
    /*    background-color: #415a77;*/
    /*}*/
    .app > img {
        width: 100%;
        /*height: 100%;*/
        margin: 0 auto;
        position: absolute;
        top: -20%;
    }
    .photo {
        position: relative;
        /*width: 180px;*/
        height: 200px;
        /*background-color: #778da9;*/
        margin: 0;
        padding: 0;
        overflow: hidden;
        text-align: center;
    }
    /*.photo:hover {*/
    /*    background-color: #415a77;*/
    /*}*/
    .photo > img {
        /*width: 100%;*/
        display: inline;
        height: 100%;
        margin: 0 auto;
        /*position: absolute;*/
        /*left: -25%;*/
    }
    .media {
        position: relative;
        /*width: 180px;*/
        height: 170px;
        background-color: #778da9;
        margin: 0;
        padding: 0;
        overflow: hidden;
        display: table-cell;
        vertical-align: middle;
    }
    /*.media:hover {*/
    /*    background-color: #415a77;*/
    /*}*/
    .media > img {
        width: 100%;
        /*height: 100%;*/
        margin: 0 auto;
        position: absolute;
        /*left: -25%;*/
    }
</style>