<template>
    <div id="main">
        <div class="content">
            WORK IN PROGRESS
        </div>
    </div>
</template>

<script>
    export default {
        name: "Main"
    }
</script>

<style scoped>
    #main {
        padding: 4vh 0;
        text-align: left;
    }
</style>