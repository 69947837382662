<template>
    <div id="logo" :style="{ width: size + 'px', height: size + 'px' }">
        <img v-if="theme" :src="yjs_0d1b2a" style="width: 100%; height: 100%;" />
        <img v-else :src="yjs_ffe05c" style="width: 100%; height: 100%;" />
    </div>
</template>

<script>
    import yjs_0d1b2a from '../assets/yjs_0d1b2a.png';
    import yjs_1b263b from '../assets/yjs_1b263b.png';
    import yjs_415a77 from '../assets/yjs_415a77.png';
    import yjs_778da9 from '../assets/yjs_778da9.png';
    import yjs_e0e1dd from '../assets/yjs_e0e1dd.png';
    import yjs_ffe05c from '../assets/yjs_ffe05c.png';
    export default {
        name: "Logo",
        props: {
            theme: Boolean,
            size: String,
        },
        data() {
            return {
                // thistheme: true,

                yjs: yjs_0d1b2a,
                // yjs: yjs_415a77,
                yjs_0d1b2a: yjs_0d1b2a,
                yjs_1b263b: yjs_1b263b,
                yjs_415a77: yjs_415a77,
                yjs_778da9: yjs_778da9,
                yjs_e0e1dd: yjs_e0e1dd,
                yjs_ffe05c: yjs_ffe05c,
            }
        },
        // watch: {
        //     theme: function () {
        //         this.thistheme = this.theme;
        //     }
        // },
        methods: {

        },
        mounted() {
            if (this.theme) {
                console.log('Day');
            }
        }
    }
</script>

<style scoped>
    #logo {
        position: fixed;
        top: 30px;
        left: 20px;
    }
</style>